<template>
  <footer class="row pieDePagina">
    <!-- <div class="col-12 px-0 boton-escritorio">
      <button class="btn btn-primary w-100 rounded-0 p-2" @click="versionEscritorio()">
        Versión de escritorio
        <font-awesome-icon icon="sign-out-alt" class="ml-1" />
      </button>
    </div> -->

    <div class="col-12 py-3 disclaimer">
      <p class="text-center small m-0">
        Los resultados presentados tienen un carácter informativo y no son definitivos,
        por tanto, no tienen efectos jurídicos. 
      </p>
    </div>

    <div class="col-12 seccion-logos">
      <div class="grilla">
        <div class="logo">
          <img src="@/assets/ieebcs.png" alt="" class="img-fluid ml-auto d-block">
        </div>
        <div class="logo">
          <img src="@/assets/PrepLogoFooter.png" alt="" class="img-fluid mr-auto d-block">
        </div>
      </div>
    </div>
    <div class="col-12 aviso-privacidad text-center pt-5 pb-2">
     <p>
        Este sitio se visualiza mejor en las versiones más recientes de los navegadores web.
     </p>

      <h5 class="my-4">
        Instituto Estatal Electoral de Baja California Sur
      </h5>

      <span>
        Versión 1.0
      </span>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'PieDePagina',
  mutations: {
    setearEstado(state, { propiedad, payload, subpropiedad = null }) {
      if (subpropiedad) {
        state[propiedad][subpropiedad] = payload;
        return null;
      }
      state[propiedad] = payload;
      return null;
    },
  },
  methods: {
    versionEscritorio() {
      this.$store.state.anchoPantalla = 3000;
      this.$store.state.esVersionEscritorio = true;
      this.$store.commit('cambiarZoom', 30);
    },
  },
};
</script>

<style lang="scss" scoped>
.boton-escritorio {
  .btn {
    background-color: #B283B9;
    color: #000000;
    
    border-color: transparent;
  }
}

.seccion-logos {
  padding: 10px;
  background-color: #582D73;
  .grilla {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    align-items: center;
    img {
      max-width: 75px;
    }
  }
}

.aviso-privacidad {
  background-color: #582D73;
  color: #fff;
}

@media (max-width: 768px) {
    .seccion-logos {
      padding: 10px;
      background-color: #B283B9;
      .grilla {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        align-items: center;
        img {
          max-width: 75px;
        }
      }
    }
    .aviso-privacidad, .disclaimer {
      font-size: 13px;
    }
    .disclaimer{
      margin: 10px;
    }
   
 }


</style>
