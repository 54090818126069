<template>
  <section>
    <div v-if="cargaTerminada">
      <MenuMovil />
      <div class="container-fluid contenido" v-if="!esBaseDatos">
          <AvanceEntidad v-show="!informacion" />
          <ConsultaVotacion v-if="!esVotoA" :seccion="seccion" />
          <slot />
          <PieDePagina />
          <AvisoPrivacidadMovilModal />
          <ModalBaseMovil id="modalBD"/>          
          <Compartir />
      </div>
      <div class=" mr-2 contenido2" v-if="esBaseDatos ">
          <AvanceEntidad v-show="!informacion " />
          <slot />
          <PieDePagina />
          <AvisoPrivacidadMovilModal />
          <ModalBaseMovil id="modalBD"/>          
          <Compartir />
      </div>

      
    </div>
    <div class="container-fluid" v-else>
      <div class="row">
        <div class="col-12">
          <div class="cargando">
            <div>
              <Spinner />
              <p class="text-center my-5">
                Cargando contenido...
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PieDePagina from '@/components/Movil/componentes/PieDePagina.vue';
import AvisoPrivacidadMovilModal from '@/components/Movil/componentes/AvisoPrivacidad.vue';
import ModalBaseMovil from '@/components/Movil/componentes/ModalBase.vue';

import Compartir from '@/components/Compartir.vue';
import Spinner from '@/components/Spinner.vue';
import MenuMovil from '../componentes/Menu/Barra.vue';
import AvanceEntidad from '../../AvanceEntidad.vue';
import ConsultaVotacion from '../componentes/ConsultaVotacion.vue';

export default {
  name: 'LayoutMovil',
  data() {
   return {
     informacion: true,
     esBaseDatos: true,
     esVotoA: true,
     esVotoAPD: true,

   };
  },
  created() {   

    this.informacion = this.$router.currentRoute.name === 'BaseDatos'
        || this.$router.currentRoute.name === 'PreguntasFrecuentes'  ;

      this.esBaseDatos = this.$router.currentRoute.name === 'BaseDatos'
        || this.$router.currentRoute.name === 'PreguntasFrecuentes' ;

      this.esVotoA = this.$router.currentRoute.name === 'VotoAnticipado' 
        || this.$router.currentRoute.name === 'VotoAnticipadoPorDistrito' ;
   
      
  },
  components: {
    MenuMovil,
    AvanceEntidad,
    ConsultaVotacion,
    PieDePagina,
    AvisoPrivacidadMovilModal,
    ModalBaseMovil,
    Compartir,
    Spinner,
  },
  props: {
    seccion: {
      type: Boolean,
      default: () => false,
    },
    extranjero: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    cargaTerminada() {
      return this.$store.state.cargaTerminada;
    },
  },
};
</script>

<style lang="scss" scoped>
.contenido {
  padding-top: 6.5rem !important;
 
}
.contenido2 {
  padding-top: 4rem !important;
//  width: 100%;
}

.cargando {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  height: 100vh;

  .sk-chase {
    margin: 0 auto;
    text-align: center;
  }
}
</style>
