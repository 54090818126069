<template>
  <div class="MiCasilla">
    <div class="grilla">
      <button class="boton-cerrar primario" @click="cerrarMiCasilla">
        <font-awesome-icon icon="arrow-left" class="fa-2x mr-2" />
      </button>
      <h2 class="primario mb-0">
        Mi Casilla
      </h2>
    </div>
    <hr>

    <form class="contenido" @submit.prevent="consultarCasilla" >
      <p class="font-weight-bold">
        Conoce los resultados de tu casilla
      </p>

      <p>
        Para conocer los resultados de tu casilla, ingresa los siguientes datos.
      </p>

      <div class="formulario-grilla">
        <div>
          <label>
            Sección
          </label>
          <input type="text" class="form-control radius-3" maxlength="3" v-model="seccion" />
        </div>
      </div>

      <p>
        Identifica el <b>número de sección</b> y
        tu <b>primer apellido</b> de acuerdo al modelo de tu credencial.
      </p>

      <img src="@/assets/Credencial-C.svg" alt="" class="img-fluid">

      <button class="btn btn-primary mx-auto d-block mb-3" type="submit">
        Consulta
      </button>

      <p class="text-center">
        Aviso de Privacidad: El uso de los datos que nos proporcionaste es
        exclusivo para visualizar el detalle de la votación de tu casilla,
        no quedarán guardados en ninguna base de datos.
      </p>
    </form>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
import useObtenerCasilla from '@/composables/useObtenerCasilla';

export default defineComponent({
  name: 'MiCasilla',
  setup(_, { root }) {
    const seccion = ref(null);

    function cerrarMiCasilla() {
      root.$store.commit('setearEstado', {
        propiedad: 'mostrarMiCasilla', payload: false,
      });
    }

    function consultarCasilla() {
      useObtenerCasilla(root, seccion.value)
        .obtenerCasilla();

      // cerrarMiCasilla();
      root.$bvModal.hide('MenuFlotanteMovil');
    }

    return {
      seccion,
      cerrarMiCasilla,
      consultarCasilla,
    };
  },
});
</script>

<style lang="scss" scoped>
.MiCasilla {
  background-color: #fff !important;
  margin: -15px;
  padding: 15px;
  height: 100vh;

  hr {
    border-width: 3px;
    border-color: black;
  }

  .grilla {
    display: grid;
    grid-template-columns: 60px 1fr;
    align-items: center;

    .boton-cerrar {
      background: transparent;
      border-color: transparent;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .contenido {
    background-color: #582D73;
    padding: 10px;
    color: #FFF;
    border-radius: 15px;

    .formulario-grilla {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      gap: 15px;
      margin-bottom: 30px;
    }

    .btn.btn-primary {
      background-color: #f393c4;
      border-color: transparent;
      padding: 5px 30px;
    }
  }
}
</style>
