<template>
  <b-modal
    id="ModalBaseMovil"
    centered
    no-close-on-backdrop
    no-close-on-esc    
    hide-footer   
    v-model="mostrarModal"
    class="detalle-card"
    
  >
    <div >
      <p class="text-center mb-2">
        {{ textoModal }}
      </p>

    
    </div>
  </b-modal>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import useObtenerCasilla from '@/composables/useObtenerCasilla';

export default defineComponent({
  name: 'ModalBaseMovil',
  data() {
    return {
      mostrarModal: false, // Estado del modal
    };
  },
  props: {
    escritorio: {
      type: Boolean,
      default: () => false,
    },
    seccion: {
      default: () => null,
    },
    textoModal: String,
  },
  methods: {
    show() {
      this.mostrarModal = true; 
    },
  },
  setup(props, { root }) {
    const abrirMiCasilla = () => {
      if (props.escritorio) {
        root.$bvModal.hide('ModalBaseMovil');
        useObtenerCasilla(root, props.seccion)
          .obtenerCasilla();
        return null;
      }

      root.$store.commit('setearEstado', {
        propiedad: 'mostrarMiCasilla',
        payload: true,
      });
      root.$bvModal.hide('ModalBaseMovil');
      return null;
    };

    return {
      abrirMiCasilla,
    };
  },
});
</script>
