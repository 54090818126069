<template>
  <b-modal
    id="MenuFlotanteMovil"
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    title="Menú"
  >
    <div v-show="!mostrarMiCasilla">
      <div class="row">
        <div class="col-12 menu-item" @click="manejarMostrarSubMenu">
          Tipo de elección
          <div class="submenu" v-if="mostrarSubMenu">
            <div class="submenu-item" v-if="gubernaturaE" @click="cambiarVista('/G/ENT/VPC')">
              Gubernatura
            </div>
            <div class="submenu-item" @click="cambiarVista('/D/ENT/VPC')">
              Diputaciones
            </div>
            <div class="submenu-item" @click="cambiarVista('/A/ENT/VPC')">
              Ayuntamientos
            </div>
          </div>
        </div>
        <router-link to="#" class="col-12 menu-item" v-b-modal.AvisoPrivacidadMovilModal>
          Mi Sección
        </router-link>
        <router-link to="/preguntas-frecuentes" class="col-12 menu-item" exact>
          Preguntas frecuentes 
        </router-link>
        <router-link to="/centro-de-ayuda" class="col-12 menu-item">
          Centro de ayuda
        </router-link>
        <router-link to="/base-datos" class="col-12 menu-item" >
          Base de datos
        </router-link>
        <a href="https://www.ieebcs.org.mx/" class="col-12 menu-item" target="_blank">
          Portal IEEBCS
        </a>
        
      </div>
      <ModoOscuro />
      <!--<zoom />-->
    </div>
    <div v-show="mostrarMiCasilla">
      <MiCasilla />
    </div>
  </b-modal>
</template>

<script>
import ModoOscuro from '@/components/ModoOscuro.vue';
import MiCasilla from '@/components/MiCasilla.vue';

export default {
  name: 'MenuFlotanteMovil',
  components: {
    ModoOscuro,
    MiCasilla,
  },
  data() {
    return {
      mostrarSubMenu: false,
    };
    return {
      gubernaturaE: false,
    };
  },  
  computed: {
    mostrarMiCasilla() {
      return this.$store.state.mostrarMiCasilla;
    },
  }, 
  methods: {
    manejarMostrarSubMenu() {
      this.mostrarSubMenu = !this.mostrarSubMenu;
    },
    cambiarVista(ruta) {
      this.$bvModal.hide('MenuFlotanteMovil');
      this.$router.push(ruta);
    },
  },
};
</script>

<style lang="scss" scoped>
.col-12 {
  padding: 0;
}
.menu-item {
  display: block;
  width: 100%;
  padding: 20px 15px;
  color: #e0e0e0;
  font-weight: 500;
  font-size: 18px;
  border-bottom: 1px solid #e0e0e0;

  &:hover {
    cursor: pointer;
  }

  .submenu {
    display: flex;
    flex-direction: column;

    .submenu-item {
      padding: 5px;
      padding-left: 30px;
      color: #c2c2ca;
      &:hover {
        color: #e0e0e0;
      }
    }
  }
}
</style>
